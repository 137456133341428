.about-me {
  text-align: justify;
}

.resume-experience-box-list{
  text-align: left;
}

.modal-text{
  text-align: justify;
}

#s-inr, #s-c-bnc {
  color: black;
}

#c-inr-i {
  color: black;
}

#c-bns #c-p-bn{
  background-color: #ffc107;
  border: 1px solid #ffc107;
  color: black
}

#c-bns #c-p-bn:hover {
  border: 1px solid #454545;
}

#s-bns #s-all-bn {
  background-color: #ffc107;
  border: 1px solid #ffc107;
  color: black
}

#s-bns #s-all-bn:hover {
  border: 1px solid #454545;

}

#c-s-bn, #s-rall-bn {
  color: #ffc107;
  background-color: #454545;

}

#s-sv-bn {
    color: #454545;
    background-color: #ffc107;
    border: 1px solid #454545;
}
